import { useCallback, useContext, useEffect, useState } from "react";
// Icon
import closeMenu from "../../assets/img/icon/closeSubMenu.svg";
import arrowRightSubmenu from "../../assets/img/icon/arrowRightSubmenu.svg";
import select from "../../assets/img/icon/select.svg";

import axios from "axios";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ConferanceCourseSideBar = () => {
  // Global State
  const {
    selectSubCategoryId,
    setSelectSubCategoryId,
    filterMenu,
    setFIlterMenu,
    // categoryData,
    // setCategoryData,
    categoryVisible,
    setCategoryVisible,
    setRenderType,
    setSearchValue,
  } = useContext(MainContext);

  // Local State
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState(["loading"]);
  const [selectCategoryData, setSelectCategoryData] = useState({
    categoryName: "",
    id: "",
    lang: "",
  });

  // React Router DOm
  const location = useLocation();

  // reset Data
  useEffect(() => {
    if (location.pathname) {
      setFIlterMenu(false);
      setRenderType("");
      setCategoryVisible(false);
      setSearchValue("");
      setSelectSubCategoryId(null);
      setCategoryData([]);
      setSubCategoryData(["loading"]);
      setSelectCategoryData({
        categoryName: "",
        id: "",
        lang: "",
      });
    }
  }, [location.pathname]);

  const { t, i18n } = useTranslation();
  // Get Course Category Data
  const getCourseCategoryData = useCallback(async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/courses/categories?per-page=1000&sort=name`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          //   navigate("/error");
        }
      });
  }, [i18n.language, setCategoryData]);



  // Conference
  const getConferanceCategoryData = useCallback(async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/conferences/categories?per-page=1000&sort=name`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          //   navigate("/error");
        }
      });
  }, [i18n.language, setCategoryData]);



    // get  SubCategory Data
    const getSubCategoryData = useCallback(
      async (query,categoryId) => {
        await axios
          .get(
            `https://api.uimconsulting.com/${i18n.language}/v3/${query}/subcategories?category=${categoryId}&sort=name`,
            {
              crossdomain: true,
            }
          )
          .then((res) => {
            setSubCategoryData(res.data);
          })
          .catch((err) => {
            if (err) {
              console.log(err);
              //   navigate("/error");
            }
          });
      },
      [i18n.language]
    );
  // Control Api
  useEffect(() => {
    if (location.pathname === "/conference") {
      getConferanceCategoryData();
    } else {
      getCourseCategoryData();
    }
  }, [getConferanceCategoryData, getCourseCategoryData, location.pathname]);
  return (
    <>
      {/* Category */}
      <div
        className={
          filterMenu ? "filter-menu scroll   active" : "filter-menu scroll "
        }
      >
        <div className="close" onClick={() => setFIlterMenu(false)}>
          <img src={closeMenu} alt="close" />
        </div>
        <span className="title">{t("side_bar.filter")}</span>
        <ul className="type-list">
          {categoryData.length !== 0 &&
            categoryData.map((category) => (
              <li
                className="type-item"
                onClick={() => {
                  setSubCategoryData(["loading"]);
                  setCategoryVisible(true);
                  setSelectCategoryData({
                    categoryName: category.name,
                    id: category.id,
                    lang: i18n.language,
                  });
                  getSubCategoryData( location.pathname === "/conference" ? "conferences" : "courses" ,category.id);
                }}
                key={category.id}
              >
                <div className="text">
                  {/* {t("side_bar.category")} */}

                  {category.name}
                </div>
                <div className="icon">
                  <img src={arrowRightSubmenu} alt="icon" />
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* SubCaregory */}
      <div
        className={
          categoryVisible
            ? "filter-menu sub-category   active     scroll  "
            : "filter-menu sub-category  scroll "
        }
      >
        <div className="close" onClick={() => setCategoryVisible(false)}>
          <img src={closeMenu} alt="close" />
        </div>
        <span className="title">{selectCategoryData.categoryName}</span>
        <ul className="type-list select ">
          {subCategoryData[0] === "loading" ? (
            Array.from({ length: 20 }, (_, index) => (
              <li className="type-item-loading" key={index}>
                <div className="text"></div>
              </li>
            ))
          ) : subCategoryData.length === 0 ? (
            <li className="type-item">
              <div className="text">not found</div>
            </li>
          ) : (
            subCategoryData.map((category) => (
              <li
                className={
                  category.id === selectSubCategoryId?.id
                    ? "type-item active"
                    : "type-item"
                }
                onClick={() => {
                  if (selectSubCategoryId?.id === category.id) {
                    setSelectSubCategoryId(null);
                    setRenderType("");
                    setCategoryVisible(false);
                    setFIlterMenu(false);
                  } else {
                    setSelectSubCategoryId(category);
                    setRenderType("category");
                    setCategoryVisible(false);
                    setFIlterMenu(false);
                  }
                }}
                key={category.id}
              >
                <div className="text">{category.name}</div>
                <div className="icon">
                  <img src={select} alt="icon" />
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  );
};

export default ConferanceCourseSideBar;
